import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Login from "../containers/login";
import Layout from "../components/layout"
import { Container } from 'reactstrap'
import generalStyles from '../components/styles/general.module.css'

export default class signin extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <Layout>
        <div className={generalStyles.containerBackground}>
          <Container
            style={{
              padding: '2rem 2rem 1rem',
              minHeight: '75vh'
            }}
          >
            <Login location={this.props.location.search} />
          </Container>
        </div>
      </Layout>
    )
  }
}
