import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { PureComponent } from "react";
import { Link, navigate } from 'gatsby'
import { FormFeedback, FormGroup, Form, Input, Row, Col, Button , Label } from 'reactstrap'
import { handleLogin, isLoggedIn , forcePasswordChange } from '../services/auth'
import Amplify, { Auth, Cache, Hub, Logger, API } from "aws-amplify";
import { CODE_SIGN_IN, CODE_COGNITO } from '../components/authcode'

import signupLogo from '../images/signup-logo.png'
import formStyles from '../components/styles/form.module.css'
import "../components/styles/layout.css";
import qs from 'query-string'

export default class Login extends PureComponent {
    constructor(props) {
        super(props);

        this.queryData = qs.parse(props.location, { ignoreQueryPrefix: true });

        this.state = {
            email: "",
            password: "",
            attempt: 4,
            respError: true,
            message: "",
            isLoading: false,
            secretCode: '',
            resetPassword : false,
            confirmSecretCode: '',
            resetMessage: '',
            isPasswordValid: false,
            respSuccess : null,
            passwordChangeStatus : false,
            errors: {
                length: false,
                uppercase: false,
                lowercase: false,
                specialChar: false,
                number: false,
                match: false,
            },
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleResetSubmit = this.handleResetSubmit.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }

    // Handle value change for the input and save it into the state
    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        }, this.validatePassword);
    }

    validatePassword() {
        const { secretCode, confirmSecretCode } = this.state;
        const errors = {
            length: secretCode.length >= 14,
            uppercase: /[A-Z]/.test(secretCode),
            lowercase: /[a-z]/.test(secretCode),
            specialChar: /\W/.test(secretCode),
            number: /\d/.test(secretCode),
            match: secretCode === confirmSecretCode,
        };

        const isPasswordValid = Object.values(errors).every(Boolean);
        this.setState({ errors, isPasswordValid });
    }

    async handleResetSubmit(event) {
        event.preventDefault();
        if (this.state.isPasswordValid) {
            this.setState({ isLoading: true });
            let { secretCode, confirmSecretCode } = this.state
            if(secretCode === confirmSecretCode){
               let data =  await forcePasswordChange(this.state)
               if(data !== "SUCCESS"){
                    this.setState({ respSuccess : "ERROR" })
               } else {
                this.setState({ passwordChangeStatus : true })
               }
            }
        } else {
            this.setState({ resetMessage: 'Passwords do not meet the criteria or do not match.' });
        }
    }

    handlePasswordChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value }, this.validatePassword);
    }

    handleSubmit = async (event) => {
        Cache.setItem("matrigger", 0)
        Cache.setItem("ma", 0)
        Cache.setItem("otpToken", "")
        event.preventDefault()
        this.setState({
            isLoading: true
        })
        await handleLogin(this.state)
            .then(async (respHandler) => {
                if (this.state.attempt > 0) {
                    switch (respHandler.code) {
                        case CODE_SIGN_IN.USER_NOT_FOUND:
                            this.setState({
                                message: "signup",
                                respError: false

                            })
                            break

                        case CODE_COGNITO.PROCESS_INVALID:
                            this.setState({
                                message: respHandler.message,
                                respError: false,

                            })
                            break

                        case CODE_SIGN_IN.USER_INCORRECT_PASSWORD:
                            this.setState({
                                message: respHandler.message === "Access Token has been revoked" ? ("You are currently logged in from another browser") : ("Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below."),
                                respError: false,
                                attempt: this.state.attempt - 1
                                //Incorrect password, You have " + this.state.attempt + " attempt(s) remaining

                            })
                            break

                        case CODE_SIGN_IN.USER_NOT_VERIFIED:
                            this.setState({
                                message: "This email is not yet verified. Please check your email for the verification link.",
                                respError: false,

                            })
                            break

                        case false:
                            console.log('Something went wrong!')
                            navigate('/')
                            break

                        case CODE_SIGN_IN.USER_BLOCKED:
                            this.setState({
                                message: respHandler.message,
                                respError: false,

                            })
                            break

                        case CODE_SIGN_IN.USER_LAMBDA_BLOCKED:
                            this.setState({
                                message: "Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.",
                                //message: "Your account has been temporarily blocked due to a number of incorrect attempts. Please unblock your account by using the Forgot Password / Resend Activation feature below.",
                                respError: false,

                            })
                            break

                        default:
                            if (this.state.password.length < 14) {
                                this.setState({
                                    resetPassword: true,
                                    message: 'Your password is too short and needs to be updated.',
                                    respError: true,
                                });
                            } else {
                                if (!this.queryData.referrer) {
                                    window.location.replace('/')
                                } else {
                                    window.location.replace(decodeURIComponent(this.queryData.referrer))
                                }
                            }
                            break
                    }
                } else {

                    this.setState({
                        message: "Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.",
                        //message: "Your account has been temporarily blocked due to a number of incorrect attempts. Please unblock your account by using the Forgot Password / Resend Activation feature below.",
                        respError: false,
                    })

                }

                this.setState({
                    isLoading: false
                })
            })
            .catch((err) => {
                this.setState({
                    isLoading: false
                })
                console.log(err)
            });
    }

    UNSAFE_componentWillUpdate() {
        if (this.state.password.length >= 14) {
            if (isLoggedIn()) {
                if (!this.queryData.referrer) {
                    window.location.replace('/')
                } else {
                    window.location.replace(decodeURIComponent(this.queryData.referrer))
                }
            }
        }
    }

    componentDidMount() {
        if (this.state.password.length >=14 ){
            if (isLoggedIn()) {
                if (!this.queryData.referrer) {
                    window.location.replace('/')
                } else {
                    window.location.replace(decodeURIComponent(this.queryData.referrer))
                }
            }
            this.forceUpdate()
        }
    }

    render() {
        const { errors, isPasswordValid, isLoading, resetMessage } = this.state;
        
        return (
            <div>
                { this.state.resetPassword ? 
                    <Row>
                        <Col
                            className={[formStyles.formContainer, formStyles.login, "row"].join(' ')}
                            sm={12} xs={12}
                            md={{ size: 8, offset : 2 }}
                        >
                            <Form onSubmit={this.handleResetSubmit} style={{ width: '100%' }}>
                                <h3 style={{ fontWeight: 'normal' }}>Change Password</h3>
                                <FormGroup>
                                <Label for="secretCode">New Password</Label>
                                    <Input
                                        id="secretCode"
                                        type="password"
                                        name="secretCode"
                                        value={this.state.secretCode}
                                        onChange={this.handleChange}
                                        className={formStyles.inputStyle}
                                        required
                                        invalid={!isPasswordValid}
                                    />
                                        { this.state.secretCode.length > 0 ? 
                                        
                                        <FormFeedback>
                                        <div style={{ color: errors.length ? 'green' : 'red' }}>
                                        Password must be at least 14 characters long.
                                    </div>
                                    <div style={{ color: errors.uppercase ? 'green' : 'red' }}>
                                        Password must contain at least one uppercase letter.
                                    </div>
                                    <div style={{ color: errors.lowercase ? 'green' : 'red' }}>
                                        Password must contain at least one lowercase letter.
                                    </div>
                                    <div style={{ color: errors.specialChar ? 'green' : 'red' }}>
                                        Password must contain at least one special character.
                                    </div>
                                    <div style={{ color: errors.number ? 'green' : 'red' }}>
                                        Password must contain at least one number.
                                    </div>
                                    </FormFeedback> : ''}
                                </FormGroup>
                                <FormGroup>
                                <Label for="confirmSecretCode">Confirm Password</Label>
                                    <Input
                                        id="confirmSecretCode"
                                        type="password"
                                        name="confirmSecretCode"
                                        value={this.state.confirmSecretCode}
                                        onChange={this.handleChange}
                                        className={formStyles.inputStyle}
                                        required
                                        invalid={!errors.match}
                                    />
                                    {!errors.match && this.state.confirmSecretCode.length > 0 && (
                                        <FormFeedback>
                                            Passwords do not match.
                                        </FormFeedback>
                                    )}
                                </FormGroup>
                                {this.state.respSuccess === "ERROR" ? (
                                    <span className='errorMessage marginBottom'>
                                        Failed to change the password, click <a href="/">here</a> to go back to the homepage
                                </span>
                                ) :  null }

                                { this.state.passwordChangeStatus ?  (<span className='successMessage'>Password has been successfully reset, click <a href='/'>here</a> to go back to the homepage</span>) : '' }
                               
                                <Button
                                    style={{ backgroundColor: 'rgb(51,81,166)' }}
                                    className={[formStyles.buttonStyles, 'float-right', 'd-none', 'd-md-block', 'topAlignedMedium '].join(' ')}
                                    disabled={isLoading || !isPasswordValid}
                                    color={isLoading ? 'secondary' : 'primary'}
                                >
                                    {isLoading ? 'LOADING...' : 'CHANGE PASSWORD'}
                                </Button>
                                <Button
                                    style={{ backgroundColor: 'rgb(51,81,166)' }}
                                    className={[formStyles.buttonStyles, 'd-md-none'].join(' ')}
                                    block
                                    disabled={isLoading || !isPasswordValid}
                                    color={isLoading ? 'secondary' : 'primary'}
                                >
                                    {isLoading ? 'LOADING...' : 'RESET PASSWORD'}
                                </Button>
                                {resetMessage && (
                                    <FormFeedback>
                                        {resetMessage}
                                    </FormFeedback>
                                )}
                            </Form>
                        </Col>
                    </Row>
                
                :  <Row >
                <Col
                    className={[formStyles.formContainer, formStyles.login, "row"].join(' ')}
                    sm={12} xs={12}
                    md={{
                        size: 7
                    }}
                >
                    <Form
                        style={{
                            width: "90%"
                        }}
                        onSubmit={this.handleSubmit}>
                        <h1 style={{
                            fontWeight: 'normal'
                        }}>
                            Sign In
                        </h1>
                        <FormGroup>
                            <Input
                                id="email"
                                autoFocus
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                className={formStyles.inputStyle}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                id="password"
                                type="password"
                                name="password"
                                required
                                autoComplete="off"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                className={formStyles.inputStyle}
                                invalid={!this.state.respError}
                            />
                            <FormFeedback invalid={!this.state.respError ? '' : undefined}>
                                {this.state.message === 'signup' ? (
                                    <p>
                                        Invalid e-mail or password, account will be locked after 5 attempts. Having trouble logging in? You may use the Forgot Password / Resend Activation feature below.
                                    </p>
                                    //Sorry, we are not able to log you in at this moment. If you have not registered, click <a href="/signup/"> here </a> to sign up now
                                ) : this.state.message}
                            </FormFeedback>
                        </FormGroup>

                        <div className="">
                            <Link to="/forgot" style={{
                                fontSize: '0.8rem',
                                color: '#007bff'
                            }}>
                                Forgot Password/Resend Activation Link
                            </Link>

                            <Button
                                style={{ backgroundColor: "rgb(51,81,166)" }}
                                className={[formStyles.buttonStyles, "float-right", "d-none", "d-md-block"].join(' ')}
                                disabled={this.state.isLoading ? true : undefined}
                                color={this.state.isLoading ? 'secondary' : 'primary'}
                            >
                                {this.state.isLoading ? "LOADING..." : "SIGN IN"}
                            </Button>

                            <Button
                                style={{ backgroundColor: "rgb(51,81,166)" }}
                                className={[formStyles.buttonStyles, "d-md-none"].join(' ')}
                                block
                                disabled={this.state.isLoading ? true : undefined}
                                color={this.state.isLoading ? 'secondary' : 'primary'}

                            >
                                {this.state.isLoading ? "LOADING..." : "SIGN IN"}
                            </Button>
                        </div>
                    </Form>
                </Col>

                <br className="d-md-none" />
                <Col
                    className={formStyles.signUpBox}
                    sm={{
                        size: 12
                    }}
                    md={{
                        size: 5
                    }}>
                    <img src={signupLogo} alt="Nets Developer Portal"
                        style={{
                            maxWidth: 110,
                            marginBottom: 'unset'
                        }} />
                    <h2 style={{
                        fontWeight: 'bolder'
                    }}>
                        Not a developer yet?
                    </h2>

                    <ul className={formStyles.listStyle}>
                        <li><i className="fas fa-chevron-right"></i> Access API Library</li>
                        <li><i className="fas fa-chevron-right"></i> Participate in Forum</li>
                        <li><i className="fas fa-chevron-right"></i> Create API Key</li>
                        <li><i className="fas fa-chevron-right"></i> and more</li>
                    </ul>
                    {/* Desktop Display */}
                    <Button
                        style={{
                            padding: "5px 20px"
                        }}
                        className={[formStyles.buttonStyles, formStyles.buttonSignUp, "float-left", "d-none", "d-md-block",].join(' ')}
                        onClick={
                            event => {
                                event.preventDefault();
                                navigate('/signup/');
                            }
                        }
                    >
                        SIGN UP NOW
                    </Button>
                    {/* Mobile Display */}
                    <Button
                        className={[formStyles.buttonStyles, formStyles.buttonSignUp, "d-md-none"].join(' ')} block
                        onClick={
                            event => {
                                event.preventDefault();
                                navigate('/signup/');
                            }
                        }
                    >
                        SIGN UP NOW
                    </Button>
                </Col>

            </Row>
                }
            </div>
        );
    }
}
